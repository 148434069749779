// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-paginated-gallery-template-js": () => import("./../src/templates/paginatedGalleryTemplate.js" /* webpackChunkName: "component---src-templates-paginated-gallery-template-js" */),
  "component---src-templates-postcard-template-js": () => import("./../src/templates/postcardTemplate.js" /* webpackChunkName: "component---src-templates-postcard-template-js" */),
  "component---src-templates-guest-book-template-js": () => import("./../src/templates/guestBookTemplate.js" /* webpackChunkName: "component---src-templates-guest-book-template-js" */),
  "component---src-templates-remembrance-form-template-js": () => import("./../src/templates/remembranceFormTemplate.js" /* webpackChunkName: "component---src-templates-remembrance-form-template-js" */),
  "component---src-templates-photo-form-template-js": () => import("./../src/templates/photoFormTemplate.js" /* webpackChunkName: "component---src-templates-photo-form-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

